import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import React from "react";
import { Row, Container, Col } from "react-bootstrap";

import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import AnchorPrismic from "../components/elements/AnchorPrismic";

import { ArrowDownBig } from "../helpers/Icons";

const Suppliers = ({ data }) => {
  if (!data) return null;

  const { title, content, supplier } = data.prismicSuppliers.data;

  const suppliers = supplier.map((item) => {
    const logoClasses = item.image.gatsbyImageData
      ? "position-absolute bottom-0 start-0 bg-white pe-none py-2"
      : "d-block text-center py-5 bg-light";

    return (
      <Col md={6} key={item.image.url || item.name}>
        <Anchor
          href={item.link.url}
          target={item.link.target}
          title={item.name}
          className="position-relative d-block mb-4 overflow-hidden"
        >
          {item.name && <span className="visually-hidden">{item.name}</span>}
          {item.image.gatsbyImageData && (
            <GatsbyImage
              image={item.image.gatsbyImageData}
              alt={item.image.alt || ""}
              className="card-image-animation bg-light"
            />
          )}
          <span className={`mb-6 px-4 ${logoClasses}`}>
            {item.logo.gatsbyImageData ? (
              <GatsbyImage
                image={item.logo.gatsbyImageData}
                alt={item.logo.alt || item.name || ""}
              />
            ) : (
              item.name
            )}
          </span>
        </Anchor>
      </Col>
    );
  });

  return (
    <Layout>
      <article className="suppliers">
        <Container>
          <header className="my-6 text-center">
            <h1 className="h4">{title}</h1>
            <ArrowDownBig size={58} />
          </header>
          <section className="mw-800 mx-auto mb-8 text-center text-primary">
            <RichText
              render={content.richText}
              serializeHyperlink={AnchorPrismic}
            />
          </section>
          <Row>{suppliers}</Row>
        </Container>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query SuppliersQuery {
    prismicSuppliers {
      ...prismicSuppliersFragment
    }
  }
`;

export default Suppliers;
